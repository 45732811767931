<template>
 <div>
  <svg viewBox="0 0 183 183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="Naamloos_verloop_8" x1="48.4" x2="145.57" y1="92.08" y2="92.08">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g data-name="Laag 2" id="Laag_2">
    <g data-name="Laag 1" id="Laag_1-2">
     <g id="icon-beer">
      <rect :fill="background" height="183" width="183"/>
      <path d="M128.75,80.86h-5.61V79.52A14.92,14.92,0,0,0,112.31,54.7a25.88,25.88,0,0,0-6.44-11,25.32,25.32,0,0,0-35.8-.45c-.3.3-.59.6-.88.91A13.08,13.08,0,0,0,53.42,62.23,14.92,14.92,0,0,0,52.16,83.3a15.26,15.26,0,0,0,3.71,3v48.72A13.1,13.1,0,0,0,69,148.13h41.1a13.09,13.09,0,0,0,13.08-13.08v-1.87h5.61a16.83,16.83,0,0,0,16.82-16.82V97.67a16.84,16.84,0,0,0-16.82-16.81ZM78.3,129.44a3.74,3.74,0,0,1-7.48,0V92.07a3.74,3.74,0,0,1,7.48,0Zm14.94,0a3.74,3.74,0,1,1-7.47,0V92.07a3.74,3.74,0,1,1,7.47,0Zm15,0a3.74,3.74,0,1,1-7.47,0V92.07a3.74,3.74,0,1,1,7.47,0Zm3.74-52.32a10.36,10.36,0,0,1-6.23-2.64,3.74,3.74,0,0,0-2.64-1.1H74.56a3.73,3.73,0,0,0-3.5,2.47,7.7,7.7,0,0,1-7.71,5,7.47,7.47,0,1,1,0-14.94,7.57,7.57,0,0,1,3.83,1.35,3.74,3.74,0,1,0,4.48-6A13.9,13.9,0,0,0,60.08,58.8a5.61,5.61,0,0,1,9.67-5.54,12.71,12.71,0,0,1,1.1,1.85,3.74,3.74,0,0,0,7.45-.41,10.76,10.76,0,0,0-2.52-5.84c-.1-.14-.23-.29-.34-.43a17.73,17.73,0,0,1,12.2-4.94,18,18,0,0,1,12.9,5.42,18.74,18.74,0,0,1,5,16.53,3.74,3.74,0,0,0,3,4.38h0a4.14,4.14,0,0,0,.7.06,3.74,3.74,0,0,0,3.67-3,26.56,26.56,0,0,0,.46-4.53,7.47,7.47,0,0,1-1.4,14.82Zm26.16,39.24a9.35,9.35,0,0,1-9.35,9.35h-5.6V88.33h5.6a9.35,9.35,0,0,1,9.35,9.35h0Z" data-name="icon-beer" id="icon-beer-2" style="fill:url(#Naamloos_verloop_8);"/>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>